.home-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem 5rem;
}
.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.page-not-found {
  height: 100vh;
  background-image: url("../assets/images/not-found.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.page-not-found .left-image {
  position: absolute;
  bottom: 0;
  left: 0;
}

.page-not-found .right-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 768px) {
  .home-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem 20px;
  }
}