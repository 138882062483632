@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to top, #D2F1DF, #FEFCF7);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.auth-background {
  background: linear-gradient(2deg, #D2F1DF 0%, #FEFCF7 100%);
}

.color-palette-wrapper {
  display: flex;
  width: 100%;
  height: 50px;
}

.color-1 {
  width: 40%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.color-2 {
  width: 30%;
  height: 100%;
}

.color-3 {
  width: 20%;
  height: 100%;
}

.color-4 {
  width: 10%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 768px) {
  .color-palette-wrapper {
    flex-direction: column;
    height: 200px;
  }

  .color-1 {
    width: 100%;
    height: 35%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    font-size: 14px;
  }

  .color-2 {
    width: 100%;
    height: 25%;
    font-size: 14px;
  }

  .color-3 {
    width: 100%;
    height: 20%;
    font-size: 14px;
  }

  .color-4 {
    width: 100%;
    height: 15%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 0px;
    font-size: 14px;
  }
}

.xx {
  border-radius: 0 !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.xx:focus {
  background-color: gainsboro !important;
}

.customCardHeader {
  background-color: var(--customBackgroundColor, defaultBackground);
  color: var(--customTextColor, defaultText);
}
